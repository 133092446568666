.latest_section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  
  .heading{
    padding-bottom: 100px;
    padding-top: 100px;
  }
  


  .heading h1{
    text-align: center;
    /* background: url(images/pngkit_blue-glow-png_1331384.png); */
    background-repeat: no-repeat;
    background-position: center bottom;
    color: rgb(255, 255, 255);
    background-size: 400px 20px;
    font-size: 40px;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
      
  }
  
  
  
  .heading h1 span{
    color: #fff;
    
    position: relative;
  }
  
  .heading h1 span::before{
    content: "";
    position: absolute;
    top: 83%;
    width: 100%;
    left: -25px;
    height: 5px;
    border-radius: 2px;
    background: linear-gradient(111.3deg, #9c27b0 9.6%, #00bcd4 93.6%);
  }


  .card{
    background: var(--bg-color);
   
    position: relative;
    /* width: 100%; */
  height: 100%;
   
  }
  
  
  .slick-slide div{
    margin: 1rem;
  border-radius: 10px;
  }
  
  
  
  .las{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

  }
  .card .content{
   display: flex;
   align-items: center;
   height: 100%;
  }
   .card .img{
    height: 90px;
    width: 90px;
   
  
    background: black;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
  }

  .apss{
    height: 100%;
  }

  .dotsa{
    display: flex;
    /* background-color: #0616f3; */
    align-items: center;
  }
  
  .dotsa div{
    /* background-color: #00acee; */
    height: 50%;
  }
  
  
  
  .card .img img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .card .details{
    /* margin-left: 80px; */
  }
  .details span{
    font-weight: 600;
    font-size: 18px;
    color: aqua;
  }
  
  .details p{
    color: #fff;
    font-size: 12px;
  }
  .card a{
    text-decoration: none;
    position: absolute;
    border-radius: 10px;
    color: #ffffff;
    background: linear-gradient(#dd04b9 0%, #0616f3 100%);
    transition: all 0.3s ease;
    top: 15px;
    right: 15px;
    padding: 5px 15px 5px 15px;
  }
  .card a:hover{
    transform: scale(0.94);
  }

  .slick-prev,
.slick-next {
  display: none !important;
}


.item{
  /* min-height: 11rem;
  min-width: 11rem; */
  padding: 10px;
  /* background: rgba(255, 255, 255,0.1); */
  border-radius: 2rem;
  backdrop-filter: blur(20px);
 
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(0, 0, 0, 0.35) 0px -2px 6px 0px inset; */
  cursor: pointer;
}

.item img{
 width: 50px;
 border-radius: 50%;
 filter: drop-shadow(5px 1px 5px rgb(0, 0, 0));
 pointer-events: none;
}

.item p{
 color: #ffffff;
 /* border:  1px solid gray; */
 width: fit-content;
 padding: 2px 7px 2px 7px;
 
 border-radius: 6px;

 font-weight: 500;
 
}

.grid_gap{
  display: grid;
  gap: 8rem;
}



@media screen and (max-width: 1024px){
  .latest_section{
    display: grid;
    grid-template-columns: 1fr ;
    align-items: center;
  }

  .las{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;

  
}
}
/* .project{
    margin-top: 100px;
    text-align: center;
    
    
}

.cen{
    display: flex;
    justify-content: center;
    margin: auto;
align-items: center;
   
    
}



.bg-card{
    display: flex;
}

.project i{
  color: #0199b4;
  box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}


.pp{
    display: flex;
    justify-content: center;
    margin-top: 40px;

}


.dd{
    width: 85%;
  
    padding: 10px;
    
}

.dd img{
    width: 100%;
    border-radius: 5%;
}
.dar {
    transition: 0.3s;
    cursor: pointer;
    width: 170px;
    
    padding: 10px;
    position: relative;
}
.dar::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px; 
    padding: 1.5px; 
    background:linear-gradient(45deg,red,blue); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
.dar img{
    border-top-right-radius: 40%;
    transition: 0.3s;
    height: 150px;
  
   padding: 10px;
}
.dar:hover img{
    border-top-right-radius: 30%;
    

}
.dar:hover {
    transform: translate(0, -10px)
}

.dd img{
    width: 100%;
    border-radius: 5%;
   
}

.rec.rec-arrow {
    border-radius: 0;
}

.rec.rec-arrow:hover{
    border-radius: 50%;
} */
