.property{
    padding-top: 3rem;
    padding-bottom: 1rem;
    display: grid;
    place-items: center;
}
.center{
    display: flex;
    justify-content: center;
    margin: auto;
align-items: center;
    color: #fff;
    padding: 15px;
}



.center button{
    padding: 1rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 15px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    height: 50px;
 box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
 margin-left: 50px;
   transition: 1s;
}

.center button:hover{
    box-shadow: 0  37px 10px -20px rgba(0, 0, 0, 0.2);
    
}

.center h1{
    font-size: 48px;
    font-family: var(--font-family);
   
    
}

.center i{
    color: #0199b4;
  box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}
.row{
    padding-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.single-property{
    width: 350px;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 30px;
    overflow: hidden;
}
.column{
    padding-left: 1rem;
    padding-right: 1rem;
}
.property-thumb{
    position: relative;
    overflow: hidden;
}

.property-thumb img{
    width: 350px;
    vertical-align: middle;
    transition: .3s;
    height: 250px;
}
.single-property:hover .property-thumb img{
    transform: scale(1.06);
}
.property-content{
    padding: 25px;
    border-bottom: 1px solid #e8e8e8;
}
.property-content h3{
    margin-bottom: 8px;
    font-size: 16px;
    color: #001d38;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}
.mark span{
    font-size: 13px;
    font-weight: 400;
    color: #919191;
    margin-left: 5px;
    font-family: "Poppins", sans-serif;
}
.amount{
    display: inline-block;
    background: #00d363;
    border-radius: 30px;
    padding: 7px 18px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    margin-top: 13px;
    font-family: "Poppins", sans-serif;
}
.property-footer{
    padding: 12px 25px;
}

.property-footer button{
    padding: 0rem 0rem;
margin-bottom: 10px;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    height: 40px;
    width: 70px;
 box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
 margin-left: 50px;
   transition: 1s;
}
.property-footer ul{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 15px;
    padding: 2px;
}

.property-footer ul:hover{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0.5px 8px 0 rgba(0, 0, 0, 0.2), 3px 6px 25px 0 rgba(0, 0, 0, 0.19);
    
}
.property-footer ul li span p h1{
    font-family: "Poppins", sans-serif;
    color: #001d38;
    font-size: 13px;
    font-weight: 400;
    margin-left: 5px;
}

.property-footer h4{
    font-family: "Poppins", sans-serif;
    color: #001d38;
    
    
    margin-left: 8px;
}
.property-footer p{
    font-family: "Poppins", sans-serif;
    color: #001d38;
    font-size: 13px;
    font-weight: 400;
    margin-left: 8px;
}
.property-footer ul li img{
    width: 46px;
    border-radius: 60px;
}
.more-property{
    padding-top: 2rem;
}
.more-property .property-btn{
    padding: 14px 31px;
    display: inline-block;
    border-radius: 2rem;
    font-size: 15px;
    border: 1px solid #fdae5c;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5px;
    color: #fdae5c;
    transition: .5s ease;
}
.more-property .property-btn:hover{
    box-shadow: 0 0 40px 40px #fdae5c inset;
    color: white;
}
@media screen and (max-width:1156px){
    .row{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width:768px){
    .property{
        padding-bottom: 2rem;
    }
    .center{
        display: block;
        text-align: center;
        padding: 15px;
    }
    .center button{
        padding: 1rem 1rem;
        color: #fff;
        background: #0199b4;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 15px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 5px;
        height: 50px;
     box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
     margin-left: 50px;
       transition: 1s;
       margin-top: 10px;
    }
    .row{
        grid-template-columns: 1fr;
    }
    .single-property{
        width: 500px;
    }
    .column{
        padding-bottom: 2rem;
    }
    .property-thumb img{
        width: 500px;
    }
    .property-content{
        padding: 15px;
    }
    .more-property{
        padding-top: .5rem;
    }
}
@media screen and (max-width:560px){
    .single-property{
        width: 450px;
    }
    .property-thumb img{
        width: 450px;
    }
    .column{
        padding-bottom: 1rem;
    }
}
@media screen and (max-width:483px){
    .center h3{
        font-size: 35px;
    }
    .single-property{
        width: 400px;
    }
    .property-thumb img{
        width: 400px;
    }
    .more-property .property-btn{
        padding: 12px 25px;
        font-size: 14px;
    }
}
@media screen and (max-width:430px){
    .single-property{
        width: 350px;
    }
    .property-thumb img{
        width: 350px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
}
@media screen and (max-width:383px){
    .property h3{
        font-size: 28px;
    }
    .property-content h3{
        font-size: 14px;
    }
    .single-property{
        width: 300px;
    }
    .property-thumb img{
        width: 300px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
    .property-footer ul li span{
        font-size: 10px;
        font-weight: 400;
        margin-left: 3px;
    }
    .property-footer ul li img{
        width: 14px;
    }
}
@media screen and (max-width:321px){
    .single-property{
        width: 270px;
    }
    .property-thumb img{
        width: 270px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
    .property-footer{
        padding: 12px 20px;
    }
}
@media screen and (max-width:291px){
    .single-property{
        width: 230px;
    }
    .property-thumb img{
        width: 230px;
    }
    .property-content{
        padding: 10px;
    }
}