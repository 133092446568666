

.subs_con{
    padding: 150px 50px 0 70px;
   
    background-size: contain;
}
.subscribe_sec{
    display: grid;
    grid-template-columns: 1fr 1fr;
     padding: 20px; 
    border: 2px solid  #2a2a2a;
    border-radius: 30px;
    backdrop-filter: blur(200px);
    background: url('../../images/hero-image-right-blur.png');
    background-repeat: no-repeat;
    background-position: left 400px bottom -80px;;
    position: relative;
    /* background-position: center; */
   
    
}

.subscribe_sec-txt{
justify-content: center;
display: grid;
width: 100%;

}

.subscribe_sec-input div{
display: flex;
justify-content: center;
position: absolute;
right: 70px;
top: -16px;
}

.subscribe_sec-input div p{
    text-align: center;
    background: #2a2a2a;
    width: 150px;
    border-radius: 5px;
    padding: 5px;
    color: #ff7200;
    font-weight:700;
    font-family: 'Poppins', sans-serif;
}

.subscribe_sec-txt h3{
font-size: 40px;
/* width: 70%; */
color: rgba(113, 236, 210, 1);
line-height: 54.05px;
font-weight: 100;
font-family: 'Poppins', sans-serif;
letter-spacing:2px;
}


.subscribe_sec-txt p{
    width: 70%;
    font-size:18px;
    color: rgba(203, 203, 203, 1);
    line-height: 28.8px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.subscribe_sec-input{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   

}

.subs-bar{
width: 100%;
max-width: 500px;
background: rgba(225, 225, 225, 0.2);
display: flex;
align-items: center;
border-radius: 60px;
padding: 5px 5px;
color: #ff7200;
}
.subs-bar input{
  background-color: transparent;
  flex: 1;
  border: 0;
  outline: none;
  padding: 10px 10px;
  font-size: 15px;
  color: #fbfbfc;  
  width: 100%
}

.subs-bar input::placeholder{
    color: #cac7ff;  
}
.subs-bar button{
    border: 0;
    border-radius: 60px;
    /* width: 60px; */
background: #00d6a8;
cursor: pointer;
padding: 10px 15px;
}

@media screen and (max-width: 1050px){
    .subs_con{
        padding: 0 50px 0 70px;
        background: url('../../images/hero-image-right-blur.png');
        background-repeat: no-repeat;
        background-position: 30% 0%;
    }
    .subscribe_sec{
    display: grid;
    grid-template-columns: 1fr;
    padding: 20px; 
}


.subscribe_sec-input{
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: flex-start;
    padding: 50px 0 0px 0;
   gap: 2rem;
 }
}

@media screen and (max-width: 650px) {
   

    
}

@media screen and (max-width: 550px) {
    .subscribe_sec-txt h3{
        font-size: 40px;
        color: rgba(113, 236, 210, 1);
        line-height: 47.05px;
        font-weight: 400;
        }

        .subs_con{
            padding: 0 45px 0 45px;
            background-position: 0% 0%;
            background-size: contain;
        }
        .subscribe_sec{
            display: grid;
            grid-template-columns: 1fr;
           padding: 20px;
        }
}

@media screen and (max-width: 440px) {
    .subscribe_sec-txt h3{
        font-size: 40px;
        color: rgba(113, 236, 210, 1);
        line-height: 47.05px;
        font-weight: 400;
        }

        .subs_con{
            padding: 100px 5px 0 5px;
            background-position: top 60px left 0px;
            background-size: contain;
        }
        .subscribe_sec{
            display: grid;
            grid-template-columns: 1fr;
            padding: 20px;
            
        }
        .subs-bar input{
  background-color: transparent;
  flex: 1;
  border: 0;
  width: 50%;
  outline: none;
  padding: 10px 10px;
  font-size: 15px;
  color: #fbfbfc;  
}
}