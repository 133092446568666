

.universeal{
    width: 90%;
    margin-inline: auto;
    max-width: 1920px;
    padding-top: 50px;
  }

.hero_section{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* height: 80vh; */
    align-items: center;
    padding: 0px 0px 0px 0px;
    
  }
  
  .hero_section span{
    color: rgb(0, 204, 255);
    font-style: italic;
  }
  .hero_section h1{
    font-size: 55px;
      font-weight: 500;
      font-family: 'Poppins';
      color: #ffffff;
      text-transform: capitalize;
      /* background: #00acee; */
      line-height: 1.2;
      position: relative;
  }
  .hero_section p{
    font-size: 16px;
      color: #b1b1b1;
      font-weight: 500;
      font-family: 'Poppins';
      width: 90%;
      padding: 15px 0;
      line-height: 1.5;
      /* background: red; */
  }
  
  .hero_section-right{
    height: 100%;
  
  }
  
  .hero_section img{
    width: 500px;
  }
  
  .hero-btn{
    gap: 1rem;
    display: flex;
  }
  
  .hero-btn button{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: 1s ease-out;
  }
  
  .hero-btn button:hover{
    /* transform: translateY(-10px); */
    box-shadow: inset 300px 0 0 0 black;
    color: #fff;
    background-color: transparent;
    transform: translateY(-10px);
  }
  
  
  
  .hero-btn-2{
    
    border: none;
    background-color: #0199b4;
 
    
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(56deg, rgba(117,0,245,1) 47%, rgba(212,0,238,1) 87%);
    padding: 1rem 1.1rem;
    cursor: pointer;
    outline: none;
    box-shadow: inset 0 0 0 0 black;
    border-width: 2px;
    border-style: solid;
    
    color: #fff;
    border-radius: 60px;
    border-color: #ffffff;
    /* border: none;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Poppins';
    text-transform: uppercase;
    padding: 12px 21px;
    border-radius: 60px;
    transition: .5s;
    background: linear-gradient(56deg, rgba(117,0,245,1) 47%, rgba(212,0,238,1) 87%);
  
  color: #fff; */
    
  }



  /* media queris for MAC */

  @media screen and (min-width: 1680px){
    .hero_section{
        display: grid;
        grid-template-columns: 1fr 1fr;
        
        align-items: center;
        padding: 90px 0 90px 0;
        
      }

     
      
  }

  @media screen and (max-width: 1024px) {
    .hero_section{
        display: grid;
        grid-template-columns:  1fr;
        
        align-items: center;
        padding: 90px 0 90px 0;
        
      }

      .hero-btn-2{
    
        border: none;
        background-color: #0199b4;
     
        
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
        background: linear-gradient(56deg, rgba(117,0,245,1) 47%, rgba(212,0,238,1) 87%);
        padding: .7rem .7rem;
        cursor: pointer;
        outline: none;
        box-shadow: inset 0 0 0 0 black;
        border-width: 2px;
        border-style: solid;
        
        color: #fff;
        border-radius: 60px;
        border-color: #ffffff;
        /* border: none;
        font-size: 22px;
        font-weight: 500;
        font-family: 'Poppins';
        text-transform: uppercase;
        padding: 12px 21px;
        border-radius: 60px;
        transition: .5s;
        background: linear-gradient(56deg, rgba(117,0,245,1) 47%, rgba(212,0,238,1) 87%);
      
      color: #fff; */
        
      }
    
     
  }


/* .push__header {
    display: grid;
    grid-template-columns: 2fr 1fr;
    
}

.push__header-content-one {
    
   
   
    padding-right: 150px;
    
  height: 400px;
    background-position: bottom;
    background-repeat: no-repeat;
   
display: flex;
justify-content: flex-start;

}

.push__header-content-one div{
 
    width: 100%;
    height: 100%;
}




.push__header-content-two {
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
   
   width: 100%;
    
}
.push__header-content-two div{
display: flex;
width: 50%;
justify-content: flex-start;

}
.push__header-content-two p {
    
    
    padding: 25px 0 25px 0;
    
        font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 24px;
   
    color: rgb(255, 255, 255);

 
    }
    .push__header-content-two h1 {
      
    
    padding: 50px 0 0 0;
    
        font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 54px;
    
    
    
    color: rgb(255, 255, 255);
    
    }
    
    .push__header-content__input {
        margin: 0rem 0 1rem;
        padding: 0 0 25px 0;
    justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        
    }
    
    
    
    
    
    .push__header-content__input button {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #0199b4;
     
        transition: ease-out 0.5s;
        text-transform: uppercase;
        font-weight: bolder;
        background: linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%);
        padding: 1rem 1.1rem;
        cursor: pointer;
        outline: none;
        box-shadow: inset 0 0 0 0 black;
        border-width: 2px;
        border-style: solid;
        border-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%) 1;
        color: #fff;
    }
    
    .push__header-content__input button:hover{
    box-shadow: inset 300px 0 0 0 black;
    color: #fff;
    background-color: transparent;
    }







.push__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.push__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.push__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.push__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.push__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .push__header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        
    }

    .push__header-content-two div{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        
        padding: 0 20px 0 20px;
        }
}

@media screen and (max-width: 750px) {
    .push__header {
        display: grid;
        grid-template-columns: 1fr ;
        
    }

    .push__header-content-one{
        padding-right: 0px;
        justify-content: center;
    }

  
}

@media screen and (max-width: 490px) {
  

  
}



@media screen and (max-width: 50em) {
}
@media screen and (max-width: 70em) {
    

    
}

@media screen and (max-width: 80em){
   
}

@media  screen and (min-width: 1680px) {
    .push__header {
        display: grid;
    grid-template-columns: 1fr 1fr;
        
    }
    .push__header-content-one {
    
    align-items: center;
 
   
    padding-right: 0px;
    
  height: 600px;
    background-position: bottom;
    background-repeat: no-repeat;
 
display: flex;
justify-content: flex-start;

}

.push__header-content-one div{
 
}

.boxi{

    width: 100%;
}




.push__header-content-two {
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: center;
  
   width: 100%;
   padding: 120px 0 120px 0;
    
}
.push__header-content-two div{
display: flex;
width: 55%;
justify-content: flex-start;

}
.push__header-content-two p {
    
 
    padding: 30px 0 25px 0;
   
        font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 30px;
    line-height: 40px;
   
    color: rgb(255, 255, 255);

    
   
    }
    .push__header-content-two h1 {
      
   
    padding: 50px 0 0 0;
    
        font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 54px;
    line-height: 54px;
    
    
    
    color: rgb(255, 255, 255);
    
    
    }
    
    .push__header-content__input {
        margin: 0rem 0 1rem;
        padding: 20px 0 26px 0;
    justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        
    }
    
    
    
    
    
    .push__header-content__input button {
        width: 100%;
        height: 100%;
        border: none;
        background-color: #0199b4;
     
        transition: ease-out 0.5s;
        text-transform: uppercase;
        font-weight: bolder;
        background: linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%);
        padding: 1rem 1.1rem;
        cursor: pointer;
        outline: none;
        box-shadow: inset 0 0 0 0 black;
        border-width: 2px;
        border-style: solid;
        border-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 44.76%, #D2AB67 100%) 1;
        color: #fff;
    }
    
    .push__header-content__input button:hover{
    box-shadow: inset 300px 0 0 0 black;
    color: #fff;
    background-color: transparent;
    }

    
}
    
    
   */
