.cap_section{
    display: flex;
    justify-content: space-between;
    /* overflow: hidden; */
    
  }

  .cap_sec_div{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 3rem;
  }

  
  .cap_section .cap_sec_card{
    backdrop-filter: blur(20px);
    /* background: rgba(0, 0, 0, 0.5); */
        font-size: 20px;
      color: rgb(0, 204, 255);
      padding: 20px 8px 20px 8px;
      text-align: center;
      font-weight: 500;
      width: 100%;
      /* border-radius: 10px; */
      border-width: 3px;
      border-style: solid;
      border-image : linear-gradient(to left, rgb(195, 0, 255), #089696);
      /* border: 10px solid; */
    border-image-slice: 1;
    border-width: 1px;
    border-left: none;
    border-right: none;
      
  }


  @media screen and (max-width: 1024px){
    .cap_sec_div{
      display: grid;
      grid-template-columns:  1fr;
      width: 100%;
      gap: 0rem;
    }
  }

/* .sec{
   
    display: flex;
   
    justify-content: center;

}

.sec_sub{
    display: block;
 
    padding: 30px 20px;
 
  text-align: center;
  color: aliceblue;

  width: 33.3333333%;
  border-width: 0.1px;
  border-style: solid;
  border-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 70%, rgba(210, 171, 103, 0.7) 100%) 1;
   
}
.re{
    width: 99.92%;
    display: flex;
    justify-content: space-between;
}



.sec_sub h4{
    -webkit-background-clip: text;
    background-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 70%, rgba(210, 171, 103, 0.7) 100%);
    font-size: 20px;
    font-weight: 400;
    -webkit-text-fill-color: rgba(0,0,0,0);
}

.sec_sub span{
    -webkit-background-clip: text;
    background-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 70%, rgba(210, 171, 103, 0.7) 100%);
    font-size: 20px;
    font-weight: 400;
    -webkit-text-fill-color: rgba(0,0,0,0);
 }



 @media screen and (max-width: 700px) {
    .sec{
        
         display: block;
         padding: 40px 20px 40px 20px;
         
     }
     .sec_sub{
   border-bottom: none;
        width: 100%;
        margin-top: 15px;
        box-shadow: none;
        box-shadow: 0.1px 0.2px 5px 0px rgba(112, 108, 108, 0.75);
        padding: 5px;
        
    }

    .re{
        width: 99%;
        display: block;
       

    }
} */