.push_org{
    display: grid;
    grid-template-columns: 1fr 1fr;
   gap: 1.5rem;
    place-items: center;
    padding-top: 100px;
  }
  
  
  .push_org-card img{
    width: 45px;
    transition: 0.2s ease;
  
  }

  .push_org-content{
    padding-right: 50px;
  }
  
  .push_org-card img:hover{
    transform: scale(1.2);
    cursor: pointer;
  }
  .push_org-cards-sec{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 3rem;
   align-items: flex-start;
  }
  
  .push_org-card{
    display: grid;
    place-items: center;
    text-align: center;
    background: url(../../images/hero-image-right-blur.png);
    backdrop-filter: blur(20px);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0.5rem 0.5rem #ff7043, -0.5rem -0.5rem #ccc;
    gap: .5rem;
    background-position: right 20px bottom 10px;
  }
  
  .push_org-card h3{
    color: rgb(0, 204, 255);
  
  }
  
  .push_org-card p{
    color: #fff;
    padding: 5px;
  }

  @media screen and (max-width: 1024px){
    .push_org{
 
      grid-template-columns: 1fr;
    
    }

    .push_org-cards-sec{
      display: grid;
      grid-template-columns:  1fr;
      grid-template-rows:  1fr;
      gap: 3rem;
     align-items: flex-start;
    }

    .push_org{
    
     gap: 1.5rem;
      place-items: center;
      padding-top: 0px;
    }
  }