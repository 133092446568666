.push__possibility {
    display: flex;
    padding: 50px;
    flex-direction: row;
    background: url("../../images/Bell.png");
    background-repeat: no-repeat;
    background-size: 200px 150px;
    background-position: right;
}

.push__possibility-image {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.push__possibility-image img {
    width: 80%;
    height: 85%;
    
}

.push__possibility-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding: 30px;
    backdrop-filter: blur(10px);
    background-color: rgba(37, 37, 37, 0.9);
    border-radius: 20px;
    transition: 0.5s;
}

.push__possibility-content:hover{
    backdrop-filter: blur(60px);
    background-color: rgba(27, 27, 27, 0.9);
}

.push__possibility-content::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px; 
    padding: 1.5px; 
    background:linear-gradient(45deg,red,blue); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
}

.push__possibility-content h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.push__possibility-content h4:last-child {
    color: #ff8A71;
}

.push__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
    color:#fff;
}

.push__possibility-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    color:#00C9A7;
    line-height: 28px;
   

}

@media screen and (max-width: 950px) {
    .push__possibility {
        flex-direction: column;
        text-align: center;
    }

    .push__possibility-image {
        margin: 1rem 0;
    }

    .push__possibility-image img {
        width: unset;
        height: unset;
    }

    .push__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .push__possibility-image img {
        width: 100%;
        height: 100%;
    }

    
}