.push_learn_head{
    
    display: grid;
    justify-content: center;
    align-items: center;
}

.push_learn_head h1{
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: 'popins', sans-serif;
    font-weight: normal;
    letter-spacing: 1px;
}

.push_learn_button{
 /* height: 15vh; */
    display: flex;
   justify-content: space-evenly;
   align-items: center;
   flex-direction: row;
    
}

.push_learn_button button{
    box-shadow: inset -7px -7px 20px 0 rgba(26, 25, 25, 0.7),
    inset 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    font-family: 'popins', sans-serif;
}

.push_learn_button button:nth-last-child(3){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  background: #416dea;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  
 
    cursor: pointer;
}

.push_learn_button button:nth-last-child(2){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  background: #019cbe;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  letter-spacing: 2px;
 
    cursor: pointer;
}

.push_learn_button button:nth-last-child(1){
    min-width: 150px;
  padding: 15px 10px;
  margin: 20px;
  cursor: pointer;
  background: #8c22f0;
  border: none;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: bold;
  
  cursor: pointer;
}


.push_learn_button button:hover{
    box-shadow: inset -5px -5px 17px 0 rgba(26, 25, 25, 0.7),
    inset 7px 7px 20px 0 rgba(0, 0, 0, 0.2);
}
.push_learn_mid{
    display: flex;
    /* height: 10vh; */
    /* height: 60vh; */
    align-items: center;
    flex-direction: row;
}
.push_learn_mid-1{
   width: 60%;
    /* height: 10vh; */
    /* background-color: black; */
display: flex;
cursor: pointer;
flex-direction: row;
justify-content: center;
}

.push_learn_mid-1 div{
   width: 650px;
   height: 340px;
 }
 .push_learn_mid-1 div iframe{
    width: 100%;
    height: 100%;
  }
.mod{
    width: 100%;
}
.mod iframe{
    border-radius: 10px;
}

.push_learn_mid-1 iframe{
    border-radius: 15px; 
    
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
   
    
 }
.push_learn_mid-2{
    display: block;
  
    width: 35%;
  
}

.push_learn_slider{
    /* height: 50vh; */
    display: flex;
    align-items: center;
    flex-direction: row;
}

.learn_vieos h1{
    padding: 10px;
    margin: 50px 50px 0 60px;
    color: rgb(56, 56, 56);
}
.detail-hd{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #8c22f0; */
    padding: 50px 0 50px 0;
    flex-direction: row;
}

.detail-hd h1{
    margin: 0px 0px 0px 50px;
    color: #05d6b7;
    font-family: 'popins',sans-serif;
    font-weight: normal;
    letter-spacing: 1px;

}
.detail-hd h3 {
   
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 13px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
 padding: 10px;
    width: 79px;

    text-align: center;
    
 box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
 margin-right: 50px;
   transition: 1s;
}

.detail-hd h3:hover{
    box-shadow: 0  37px 10px -20px rgba(0, 0, 0, 0.2);
}

.push_learn_mid-2 h1{
    color: #05d6b7;
   margin-bottom: 10px;
   font-family: 'popins',sans-serif;
   font-weight: normal;
}

.push_learn_mid-2 h4{
    color: #fff;
    font-family: 'popins',sans-serif;
    font-weight: normal;
    letter-spacing: 1px;

}

.push_learn_video iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
  
}









.learn_grid_vid_cat{
    display: flex;
   flex-direction: row;
}

.learn_grid_col-1{
    width: 50%;
    display: flex;
    justify-content: center;
    /* height: 320px; */
    align-items: end;
    flex-direction: row;
}

.learn_grid_col-1 iframe{
  border-radius: 20px;
  box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
  width: 100%;
    height: 100%;
}

.learn_grid_col-s{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    /* height: 320px; */
    align-items: end;
    flex-direction: row;
}

.learn_grid_col-f{
    width: 50%;
    display: flex;
    justify-content: center;
    /* height: 320px; */
    align-items: end;
    flex-direction: row;
}
.learn_grid_col-f iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}
.learn_grid_col-2{
   display: flex;
   flex-direction: row;
    width: 50%;
}
.learn_grid_right-1{
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: end;
    flex-direction: row;
}

.learn_grid_left-1{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    align-items: end;
    flex-direction: row;
}
.learn_grid_left-1 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}

.learn_grid_right-1 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}
.learn_grid_right-2{
    display: grid;
    align-items: end;
    width: 50%;
    justify-content: center;
    
    
}

.learn_grid_left{
display: flex;
justify-content: center;
flex-direction: row;
}

.learn_grid_left iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}

.learn_grid_left-2{
    display: flex;
    width: 50%;
    flex-direction: column;
    /* /* justify-content: center; */
    justify-content: space-between;
    align-items: center; 
}
.learn_grid_left-2 iframe{
    border-radius: 20px;
    /* margin-top: 40px; */
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}

.learn_grid_right-2 iframe{
    border-radius: 20px;
    box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
    width: 100%;
    height: 100%;
}

.push_vix_1{
    height: 300px;
    width: 650px;
}
.push_vix_2{
    height: 300px;
    width: 650px;
}


.push_vix_3 ,.push_vix_4{
    height: 140px;
    width: 300px;
}

.push_vix_5{
    margin-bottom: 20px;
}

.push_vix_5 ,.push_vix_6{
    height: 140px;
    width: 300px;
}

.push_vix_8{
    height: 300px;
    width: 650px;
}

.push_vix_7{
    height: 300px;
    width: 650px;
}

@media screen and (max-width: 1308px){


    .learn_grid_vid_cat{
        
        display: flex;
       flex-direction: column;
       align-items: center;
    }
    .push_vix_1{
        margin-bottom: 10px;
        height: 390px;
        width: 1150px;
    }
    .push_vix_2{
        height: 400px;
        width: 500px;
    }
    
    
    .push_vix_3 ,.push_vix_4{
        height: 180px;
        width: 500px;
    }
    
    .push_vix_5{
        margin-bottom: 20px;
    }
    
    .push_vix_5 ,.push_vix_6{
        height: 190px;
        width: 490px;
    }
    
    .push_vix_8{
        height: 390px;
        width: 1150px;
    }
    
    .push_vix_7{
        height: 400px;
        width: 570px;
    }
    .learn_grid_left-1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }

    .learn_grid_col-s{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        /* height: 320px; */
        align-items: end;
        flex-direction: row;
        margin-bottom: 10px;
    }
    .learn_grid_col-2{
        display: flex;
        flex-direction: row;
         width: 100%;
     }
     .detail-hd{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background-color: #8c22f0; */
        padding: 50px;
        flex-direction: row;

    }
}
@media screen and (max-width: 1244px){
    .push_learn_mid{
        display: flex;
        /* height: 10vh; */
        /* height: 60vh; */
        align-items: center;
        flex-direction: column;
    }
    .push_learn_mid-1{
        width: 100%;
        
    }

    .push_learn_mid-2{
        width: 100%;
        margin: 10px 0 10px 0;
        text-align: center;
    }
}

@media screen and (max-width: 1093px){
    .push_learn_button button:nth-last-child(3){
        min-width: 100px;
      padding: 10px 5px;
      margin: 0px;
      background: #416dea;
      border: none;
      border-radius: 25px;
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: bold;
      
      
      box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
        7px 7px 20px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
    
    .push_learn_button button:nth-last-child(2){
        min-width: 100px;
        padding: 10px 5px;
        margin: 0px;
      background: #019cbe;
      border: none;
      border-radius: 25px;
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: bold;
      
      letter-spacing: 2px;
      box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
        7px 7px 20px 0 rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }
    
    .push_learn_button button:nth-last-child(1){
        min-width: 100px;
      padding: 10px 5px;
      margin: 0px;
      cursor: pointer;
      background: #8c22f0;
      border: none;
      border-radius: 25px;
      color: rgb(255, 255, 255);
      font-size: 15px;
      font-weight: bold;
      
      letter-spacing: 2px;
      box-shadow: -7px -7px 20px 0 rgba(255, 255, 255, 0.7),
        7px 7px 20px 0 rgba(0, 0, 0, 0.2);
    }
    .push_vix_1{
       
        margin-bottom: 10px;
        height: 390px;
        width: 700px;
    }
    .push_vix_8{
        height: 390px;
        width: 700px;
    }
    .learn_grid_col-1{
        width: 100%;
        display: flex;
        justify-content: center;
        /* height: 320px; */
        align-items: center;
        flex-direction: column;
    }
    .learn_grid_col-2{
        display: flex;
        flex-direction: column;
         width: 100%;
     }

     .learn_grid_right-1{
        margin-bottom: 10px;
     }
     .push_vix_3 ,.push_vix_4{
        height: 180px;
        width: 1220;
        margin-bottom: 10px;
    }

    .learn_grid_right-2{
        display: grid;
        align-items: end;
        width: 100%;
        justify-content: center;
        
        
    }
    .learn_grid_left-2{
        display: flex;
        width: 100%;
        flex-direction: column;
        /* /* justify-content: center; */
        justify-content: space-between;
        align-items: center; 
    }
    .learn_grid_col-s{
        width: 100%;
        display: flex;
        justify-content: center;
        /* height: 320px; */
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .push_vix_7{
        height: 400px;
        width: 570px;
        margin-top: 10px;
    }
    .learn_grid_right-1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }
}

@media screen and (max-width: 600px){
    .push_vix_1{
       
        margin-bottom: 10px;
        height: 390px;
        width: 400px;
    }
    .push_vix_8{
        height: 390px;
        width: 300px;
    }
    .learn_grid_col-1{
        width: 100%;
        display: flex;
        justify-content: center;
        /* height: 320px; */
        align-items: center;
        flex-direction: column;
    }
    .learn_grid_col-2{
        display: flex;
        flex-direction: column;
         width: 100%;
     }

     .learn_grid_right-1{
        margin-bottom: 10px;
     }
     .push_vix_3 ,.push_vix_4{
        height: 180px;
        width: 520;
        margin-bottom: 10px;
    }

    .learn_grid_right-2{
        display: grid;
        align-items: end;
        width: 100%;
        justify-content: center;
        
        
    }
    .learn_grid_left-2{
        display: flex;
        width: 100%;
        flex-direction: column;
        /* /* justify-content: center; */
        justify-content: space-between;
        align-items: center; 
    }
    .learn_grid_col-s{
        width: 100%;
        display: flex;
        justify-content: center;
        /* height: 320px; */
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .push_vix_7{
        height: 400px;
        width: 570px;
        margin-top: 10px;
    }
    .learn_grid_right-1{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }
    .push_learn_mid-1 iframe{
        border-radius: 15px; 
        margin:  10px 0 10px 0;
        box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75);
       
        
     }
}


@media screen and (max-width: 300px){

    .push_vix_3 ,.push_vix_4{
        height: 140px;
        width: 100px;
    }
    
    
    .push_vix_5 ,.push_vix_6{
        height: 140px;
        width: 100px;
    }
 
}

@media screen and (max-width: 400px){

    .push_vix_3 ,.push_vix_4{
        height: 140px;
        width: 270px;
    }
    .push_vix_8,.push_vix_1{
        height: 140px;
        width: 270px;
    }
    
    .push_vix_5 ,.push_vix_6{
        height: 140px;
        width: 270px;
    }
    .push_vix_7 ,.push_vix_2{
        height: 140px;
        width: 270px;
    }
    .detail-hd{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background-color: #8c22f0; */
        padding: 50px 0 50px 0;
        flex-direction: row;

    }
    .push_learn_mid-1{
        width: 100%;
         /* height: 10vh; */
         /* background-color: black; */
     display: flex;
     cursor: pointer;
     flex-direction: row;
     }
     .push_learn_mid-1 iframe{
        width: 100%;
       
     }
     .push_learn_mid-1 div{
        height: 140px;
        width: 270px;
      }
      .push_learn_mid-1 div iframe{
         width: 100%;
         height: 100%;
       }
       .push_learn_head h1{
        color: rgb(56, 56, 56);
        text-align: center;
        font-size: 25px;
    }
    .push_learn_button{
        padding: 20px 0 20px 0;
    }
}


@media screen and (max-width: 500px){

    .push_vix_3 ,.push_vix_4{
        height: 140px;
        width: 270px;
    }
    .push_vix_8,.push_vix_1{
        height: 140px;
        width: 270px;
    }
    
    .push_vix_5 ,.push_vix_6{
        height: 140px;
        width: 270px;
    }
    .push_vix_7 ,.push_vix_2{
        height: 140px;
        width: 270px;
    }
    .detail-hd{
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* background-color: #8c22f0; */
        padding: 50px 0 50px 0;
        flex-direction: row;

    }
    .push_learn_mid-1{
        width: 100%;
         /* height: 10vh; */
         /* background-color: black; */
     display: flex;
     cursor: pointer;
     flex-direction: row;
     }
     .push_learn_mid-1 iframe{
        width: 100%;
       
     }
     .push_learn_mid-1 div{
        height: 140px;
        width: 270px;
      }
      .push_learn_mid-1 div iframe{
         width: 100%;
         height: 100%;
       }
       .push_learn_head h1{
        color: rgb(56, 56, 56);
        text-align: center;
        font-size: 25px;
    }
    .push_learn_button{
        padding: 20px 0 20px 0;
    }
}





