
.backdro{
    backdrop-filter: blur(500px);
}

.full_container{
    /* background: radial-gradient(879px at 10.4% 22.3%, rgb(255, 235, 238) 0%, rgb(229, 230, 252) 93.6%);     */
    background: transparent;
     display: flex;
     /* background-position: top; */
     backdrop-filter: blur(200px);
     background-color: #141619;
 }
 .fils{
     width: 25%;
     
     display: flex;
     height: auto;
     justify-content: center;
     padding-bottom: 20px;
    /* background-color: #01428d; */
     /* align-items: center; */
     
 }
 
 .filters{
     margin-top: 10px;
     /* backdrop-filter: blur(200px); */
     background: transparent;
     /* border: 1px solid #7a59ff; */
     border-radius: 20px;
     width: 90%;
     /* border-right: 0.1px solid rgb(221, 220, 220); */
     padding: 15px;
     position: relative;
     height: auto;
   /* border-radius: 15px; */
   /* background: linear-gradient(106.5deg, rgba(255, 215, 185, 0.91) 23%, rgba(223, 159, 247, 0.8) 93%); */
   /* backdrop-filter: blur(10px); */
   /* color: white; */
   /* font-size: 48px; */
   /* box-shadow: 0px 0px 5px rgba(0,0,0,0.5); */

 }

 .filters::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 20px; 
    padding: 1.5px; 
    background:linear-gradient(45deg,red,blue); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
            z-index: -1;
 }
 
 .containi{
     width: 75%;
     color: rgb(211, 211, 211);
 }
 
 .filter_head{
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: transparent;
     /* background-color: #127df7; */
    /* box-shadow: 0px 3px 1px 0px rgb(218, 218, 218); */
     height: 50px;
     padding: 5px;
 }
 
 .filter_head h1{
     font-size: 17px;
     color: #ffffff;
     font-family: 'popins', sans-serif;
 
 }
 
 .filters button{
     background: transparent;
     font-family: 'popins', sans-serif;
     padding: 5px;
    outline: none;
    color: #FFF;
    border-radius: 18px;
    border: 1px solid #4d4d4d;
    cursor: pointer;
    /* margin-top: 10px; */
    
    /* transition: 0.s; */
 }
 
 
 
 .filters button:hover{
    background: #7a59fd;
    font-family: 'popins', sans-serif;
    padding: 7px;
   outline: none;
   color: #FFF;
   border-radius: 18px;
   border: none;
 }
 
 .container_head{
     display: flex;
     align-items: center;
     justify-content:space-between;
     /* background-color: #fff; */
     padding-left: 10px;
     /* height: 60px; */
     /* border-bottom: 1px solid #4647479c;
     border-left: 1px solid #4647479c; */
     padding: 10px;
 }

 .container_head .container_head-sub{
    width: 98%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    /* background-color: #fff; */
    padding: 0 10px 0 10px;
    height: 60px;
    /* border: 1px solid #7a59ff; */
    border-radius: 10px;
    position: relative;
 }

 .container_head .container_head-sub::before{
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px; 
    padding: 1.5px; 
    background:linear-gradient(45deg,red,blue); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
            z-index: -1;
 }

 
 
 .container_head h5{
    
  font-size: 15px;
 }
 .side-b{
     display: none;
 }

 .sort{
    justify-content: center;
    /* width: 300px; */
    display: flex;
    width: auto;
    border: 1px solid #2a2a2a;
    border-radius: 5px;
    background: #2a2a2a65;
}
 .hs{
     /* width: 80px; */
     /* height: 30px; */
     color: #7a59ff;
     border-radius: 5px;
     /* padding: 10px 25px#645074; */
    
     font-weight: 400;
     background: transparent;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
   /* border: 1px solid #2a2a2a; */
      padding: 5px;
 }

 .hs-icon{
    font-size: 25px;
 }
 
 

 
 .container_head select{
     /* padding: 5px; */
     border-radius: 5px;
     border: none;
     /* margin-left: 12px; */
     background-color: transparent;
    
      color:#7a59ff;
      font-family: 'popins', sans-serif;
      font-size: 15px;
 }
 .container_head option{
    color: #000;
    
 }

 .container_head select:focus{
     outline: none;
 }
 
 .container_head i{
     display: flex;
     align-items: center;
     justify-content:flex-end;
     background-color: rgb(194, 193, 193);
     height: 10px;
     color: #000;
     
 }
 


 .sort h5{
    display: initial;
}
.grid-icon{
    color: #7a59ff;
    font-size: 20px;
 }
 .container_head .grid i{
    
     font-size: 20px;
     color: #7a59ff;
     cursor: pointer;
 
 }
 .grid{
     /* width: 8%s; */
     justify-content: center;
     display: flex;
     /* background: #127df7; */
     border: 1px solid #2a2a2a;
     border-radius: 5px;
     background: #2a2a2a65;
 }
 
 .grid i{
   background-color: transparent;


 }
 
 .grid div{
     /* margin: 5px; */
     /* width: 150px; */
     /* height: 30px; */
     /* color: #6b6b6b; */
     /* border-radius: 5px; */
     
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
   padding: 6px;
   
      
 }
 .grid-in-1{
    border-right: 1px solid #333333;
 }
 .grid div:hover{
    background: #131313ea;
 }
 
 .actit{
     background-color: #6b6b6b;
 }
 
 .catie{
     display: flex;
     justify-content: space-between;
     padding:  0 7px;
     /* height: 40px; */
     align-items: center;
     /* background: #f5f8ff; */
     color: #7a59ff;
     font-family: 'popins', sans-serif;
     border-radius: 10px;
     padding: 15px 0 0 0;
     /* margin-top: 10px; */
     /* border-radius: 15px; */
     /* box-shadow: 1px 2px 1px rgb(231, 231, 231); */
 }

 
 .catie h4{
    font-size: 13px;
    letter-spacing: 0.3px;
 }
 .catie p{
     /* display: block; */
  
   padding: 2px;
   /* line-height: 50px; */
   /* margin: 3em auto; */
   text-align: center;
   color: #FFF;
  
   font-size: 13px;
   /* font-weight: bold; */
   /* text-transform: uppercase; */
   border-radius: 50em;
   /* background: #35a785; */
   box-shadow: 0 3px 0 rgba(0, 0, 0, 0.07);
 }
 
 .catie p i{
     /* background-color: #7a59ff; */
     /* border-radius: 36px; */
     /* padding: 3px; */
     color: rgb(255, 255, 255);
     cursor: pointer;
     /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5), */
     /* 7px 7px 20px 0px rgba(0,0,0,.1), */
     /* 4px 4px 5px 0px rgba(0,0,0,.1); */
  }
 
 .cati_sub{
     justify-content: center;
     display: flex;
     width: 100%;
     height: auto;
     padding: 20px 0px;
     overflow: hidden;
     border-bottom: 1px solid #2a2a2aa1;
     
 }
 
 .cati_sub .buts{
     display: flex;
     width: 90%;
     flex-wrap: wrap;
     justify-content: flex-start;
     gap: 5px;
     align-items: center;
 
 }
 
 .cati_sub .buts button{
    
 
    gap: 15px;
     color: rgb(48, 47, 47);
     
     padding: 3px;
   padding: 4px 5px;
     border: 1px solid rgb(209, 209, 209);
     border-radius: 36px;
     padding: 10px 25px#645074;
     
     font-weight:500;
     font-size: 12px;
     background: #fff;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      
     outline: none;
 }
 
 .cati_sub .buts button:hover{
     background-color: #f4f4f4;
 }
 .cati_sub .wish{
     display: flex;
     width: 100%;
     flex-wrap: wrap;
     justify-content: flex-start;
     gap: 5px;
     align-items: center;
 overflow: initial;
 }
 
 .cati_sub .wish button{
     gap: 15px;
     color: rgb(48, 47, 47);
     
     padding: 3px;
   padding: 4px 5px;
     border: 1px solid rgb(209, 209, 209);
     border-radius: 36px;
     padding: 10px 25px#645074;
     
     font-weight:500;
     font-size: 12px;
     background: #fff;
     cursor: pointer;
     transition: all 0.3s ease;
    
    justify-content: center;
   display: flex;
   align-items: center;
      
     outline: none;
 }
 
 .cati_sub .wish button:hover{
     background-color: #f4f4f4;
 }
 
 .con{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 2rem;
    justify-content: center;
    /* height: 85vh; */
    /* overflow: auto; */
    /* flex-direction: row; */
    /* flex-wrap: wrap; */

    /* justify-content: flex-start; */
   /* justify-self: flex-start; */
    /* height: 130vh; */
        /* overflow: auto; */
}
.con a{
    width: 200px;
    height: 200px;
    border-radius: 20px;
}
.carda{
    width: 200px;
    height: 200px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: rgba(0, 0, 255, 0.25) 0px 50px 100px -30px, rgba(255, 255, 255, 0.3) 0px 30px 60px -30px, rgba(0, 0, 0, 0.35) 0px -2px 6px 0px inset;
   transition: 0.10ms;
  /* margin: 2px; */
    /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
  
}
.carda:hover{
    box-shadow: rgba(0, 0, 255, 0.25) 0px 50px 100px -60px, rgba(255, 255, 255, 0.3) 0px 30px 60px -60px, rgba(64, 0, 107, 0.35) 0px -2px 6px 0px inset;
}

.card_img{
    width: 100%;
    height: 80%;
    object-fit:fill;
    border-radius: 15px;
    
}


.card_body{
   height: 100%;
   width: 100%;
    
   bottom: -52%;
  position: absolute;
    background-color: #1f3d4746;
    backdrop-filter: blur(15px);
    border-radius: 15px;
    padding: 30px;
    color: #000;
   
  
    justify-content: center;
    transition: 1s;
}


.carda:hover .card_body{
bottom: -17%;
}
 
 .class-title{
 text-transform: uppercase;
 font-size: 20px;
 font-weight: 500;
 }
 
 .card-sub-title{
     text-transform: uppercase;
     font-size: 14px;
     font-weight: 300;
 }
 
 .card-info{
     font-size: 16px;
     line-height: 25px;
     margin: 40px 0;
     font-weight: 400;
 }
 
 .card-btn{
     color: #1f3d47;
     background-color: #8fabba;
     padding: 10px 20px;
     border-radius: 5px;
     text-transform: capitalize;
     border: none;
     outline: none;
     font-weight: 500;
     width: 120px;
 }
 
 
 
 .frst_prt-one{
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .frst_prt-one h1{
    font-size: 15px;
    color: #fff;
 }
 
 .frst_prt-one img{
     width: 40px;
     height: 40px;
     border-radius: 50%;
     margin-bottom: 10px;
    
  }
  .frst_prt-two {
     display: flex;
     justify-content: center;
  }
 
  .frst_prt-four{
     display: flex;
     justify-content: space-between;
  }
 
  
 .frst_prt-four .price{
     background-color: #fff;
     padding: 1px 6px;
     border-radius: 15px 0 15px 0 ;
     font-size: small;
 }
 
 .frst_prt-four .marcap{
     
     font-size: small;
 }
  .frst_prt-three p{
     text-align: center;
     font-size: 12px;
     color: #fff;
  }
 
 
  .frst_prt-two h5{
 
 color: #fff;
 
  }
  .live-chip{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 99;
  }
 
  span.live-icon{
     display: inline-block;
     position: absolute;
     /* top: calc(50% - 5px); */
     background-color: green;
     width: 10px;
     height: 10px;
     /* margin-left: 2px; */
     border: 1px solid rgba(black, .1);
     border-radius: 50%;
     z-index: 1;
     
   }

   
 .marcap{
     color: white;
 }
 
   span.live-icon ::before{
     content: '';
     display: block;
     position: absolute;
     background-color: green;
     width: 100%;
     height: 100%;
     border-radius: 50%;
     animation: live 2s ease-in-out infinite;
     z-index: -1;
   }
 
 .list-co{
     /* display: grid; */
     display: flex;
     flex-direction: column;
 gap: 10px;
 /* height: 100vh; */
 padding: 30px;
 /* overflow: auto; */
 }
 
   .lis{
     display: flex;
     /* margin: auto; */
     /* border-radius: 15px; */
     box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02),
     5px 2px 2px rgba(0, 0, 0, 0.028), 1px 5.1px 5.1px rgba(0, 0, 0, 0.035),
     0 5px 2.1px rgba(0, 0, 0, 0.042), 1px 3.7px 2.8px rgba(0, 0, 0, 0.05),
     0 1px 1px rgba(0, 0, 0, 0.07);
     background-color: #253648;
     border-radius: 20px;
     /* height: 125px; */
     
   }
   .list-head .price{
     background-color: #ff7b3e;
     padding: 5px 5px;
     border-radius: 15px 0 15px 0 ;
     font-size: small;
     
 }
 
 .list-head h2{
     color: #00d6a8;
     font-family: 'popins',sans-serif;
     font-weight: normal;
 }
 
   .list-img{
     width: 15%;
     
     background-color: #253648;
     display: flex;
     border-radius: 20px;
   }
   .list-img img{
     width: 55%;
     /* height: 65%; */
     margin: auto;
     border-radius: 50%;
   }
 
   .list-contant{
     width: 85%;
     background-color: #253648;
     /* background: conic-gradient(from 180deg at 50% 50%, #DBC8EB 0deg, rgba(152, 106, 210, 0.398102) 141.07deg, rgba(234, 194, 218, 0.58) 20.13deg, rgba(204, 225, 236, 0.25) 30deg, #DBC8EB 360deg); */
     background-position:center;
     background-size: cover;
     margin-left: 20px;
     border-top-right-radius: 20px;
     border-bottom-right-radius: 20px;
   }
 
   .list-head{
     display: flex;
     justify-content: space-between;
     padding: 10px;
     color: #000;
   }
   .btn_live{
     display: flex;
     align-items: center;
 }
 
 .btn_live span{
    margin-left: 5px;
    /* margin-top: -17px; */
  
 }
 
 .list-des{
     padding: 7px;
     color: rgb(255, 255, 255);
     width: 90%;
    font-size: 14px;
    font-family: 'popins',sans-serif;
 }
 .list-social{
     display: flex;
     
 }
 .list-bottom{
     display: flex;
     justify-content: center;
     bottom: 0;
     width: 85%;
     margin-left: 8px;
 }
 .op{
     display: flex;
     justify-content: center;
 }
 .op img{
     width: 15px;
     border-radius: 60px;
 }
 
 .list-bottom ul{
     display: flex;
     justify-content: space-between;
    
 }
 .list-bottom li{
     margin: 5px;
    
 }
 
 .list-market,.list-num{
     color: rgb(0, 0, 63);
     font-size: 13px;
     font-weight: 500;
 }
 
 
 .list-bottom .fa-facebook-f{
 color: #232ecf;
 }
 .list-bottom .fa-github{
     color: #6b6b6b;
     }
     .list-bottom .fa-twitter{
         color: #127df7;
         }
 .list-right{
     display: flex;
     width: 12%;
     justify-content: flex-end;
     color: cornsilk;
 }
 
 .list-right .btn_live{
     color: green;
 }
 
 @keyframes live {
   0% {
     transform: scale(1,1);
   }
   100% {
     transform: scale(3.5,3.5);
     background-color: green;
   }
 }
 
 
 
 .frst_prt-three ul {
 display: flex;
 list-style: none;
 }
 
 .frst_prt-three ul .fa-facebook-f{
     color: rgb(32, 32, 112);
     filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
     }
 
     .frst_prt-three ul .fa-twitter{
         color: rgb(34, 122, 173);
         filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
         }
 
         .frst_prt-three ul .fa-github{
             filter: drop-shadow(0 0.2rem 0.25rem rgba(3, 0, 0, 0.2));
         }
 
 
    
 
 .frst_prt-three ul li{
     margin: 5px;
     }
 
 .frst_prt-three{
     display: flex;
     justify-content: center;
 }
 
 .pro_banner{
     /* height: 250px; */
     width: 100%;
     background: #000;
     background-repeat: no-repeat;
     background-position: center;
     background-size: cover;
     display: flex;
     /* box-shadow: -1px 13px 24px -15px rgba(245,193,245,1); */
     border-bottom: 1px solid #4647479c;
     align-items: center;
     justify-content: space-evenly;
     padding: 20px 0 20px 0;
     flex-direction: row;
 }
 
 .box{
     border-radius: 24px;
     background: transparent;
    display: grid;
     justify-content: center;
    padding-top: 10px;
    align-items: center;
     text-align: center;
     width: 30%;
 height: 170px;
 /* background-color: #f4f4f44b; */
 /* box-shadow: 0  17px 10px -10px rgba(167, 166, 166, 0.4); */
 background: rgba(99, 108, 132, 0.26);
 border: 2px solid #2a2a2a;
 }
 
.box-add-shad{
    width: 30%;
    border-radius: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
 .box-add{
   
    padding: 15px;
    border-radius: 15px;
    background: rgba(99, 108, 132, 0.26);
box-shadow: inset 0px 0px 68px rgba(255, 255, 255, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.15);
backdrop-filter: blur(43px);

 
 }

 .box-add .box-add-sub{
    border-radius: 24px;
    display: flex;
    gap: 4px;
     justify-content: center;
    padding-top: 10px;
    align-items: center;
     text-align: center;
     
 height: 170px;
 border: 2px solid hsl(0, 0%, 16%);
 /* background: rgba(99, 108, 132, 0.26); */
 position: relative;
 /* overflow: hidden; */
 /* box-shadow: 1px 10px 10px 10px orange; */
 border-radius: 24px;
 /* background: rgba(99, 108, 132, 0.26); */
/* box-shadow: inset 0px 0px 68px rgba(255, 255, 255, 0.05), inset 0px 4px 4px rgba(255, 255, 255, 0.15); */
backdrop-filter: blur(43px);
/* left: calc(50% - 1196px/2 - 7px);
top: calc(50% - 689px/2 + 16.5px); */

background: linear-gradient(144.48deg, #28272F 9.13%, #040404 62.89%);
border: 2px solid rgba(255, 255, 255, 0.3);
box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.4);
 }
 .box-add .box-add-sub:hover{
    box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.685);
 }


 

 
 .box-add .box-add-sub::before,.box-add-sub::after{
    content: "";
    z-index: -1;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background: linear-gradient(162deg, rgba(106,56,245,1) 1%, rgba(234,36,90,1) 21%, rgba(235,129,69,1) 62%, rgba(30,153,254,1) 80%, rgba(201,133,100,1) 99%); */
    /* background: linear-gradient(270deg, rgba(106,56,245,1) 0%, rgba(234,36,90,1) 21%, rgba(235,129,69,1) 40%, rgba(30,153,254,1) 74%, rgba(201,133,100,1) 91%); */
    /* background: linear-gradient(180deg, rgba(106,56,245,1) 0%, rgba(234,36,90,1) 36%, rgba(235,129,69,1) 69%, rgba(30,153,254,1) 86%, rgba(201,133,100,1) 98%); */
    background: linear-gradient(-125deg, #ff1b6b 20%,  #45caff 40% );
    filter: blur(0px);
    opacity: var(1);
    /* transition: opacity 0.3s; */
    border-radius: inherit;
    mix-blend-mode: overlay;

 }

 .box-add-sub::after{
    filter: blur(10px);
 }
 


 .box-add span{
    display: flex;
    position: absolute;
    left: -10px;
    top: 5px;
    align-items: center;
    background: linear-gradient(117.58deg, rgba(146, 146, 146, 0.37) -47.79%, rgba(204, 235, 235, 0.322) 100%);
    /* Glass/Effects */
    
    box-shadow: 0px 24px 24px -16px rgba(0, 0, 0, 0.12), inset 16px 24px 64px -24px rgba(255, 255, 255, 0.08), inset 0px 8px 12px rgba(255, 255, 255, 0.08);
    border-radius: 12px;
    gap: 10px;
    padding: 5px 15px 5px 15px;
    border-radius: 5px;
 }
 .box-add span p{
    font-size: 15px;
    font-family: 'popins', sans-serif;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: 1px;
 }

 .box-add span img{
    width: 13px;
    border-radius: 50%;
 }
 
 
 .pro_banner .pros-y img{
 /* height: 50px; */
 width: 50px;
 /* border-radius: 50%; */
 }

 .pro_banner .pros-g img{
    /* height: 50px; */
    width: 50px;
    /* border-radius: 50%; */
    }

    .box-add a p{
        color: #000fe4;
        transition: 0.1ms;
        z-index: 999;
        position: absolute;
        right: 10px;
        bottom: 10px;
        background: #ffffff;
        border-radius: 5px;
        font-size: 15px;
        padding: 4px;
        font-weight: 500;
        font-family: 'popins', sans-serif;
        display: flex;
        align-items: center;
        gap: 3px;
     }
     .box-add a p:hover{
        color: #232ecf;
     }

 .pros-add img{
    position: absolute;
    height: 100%;
 width: 100%;
 /* border-radius: 50%; */
 z-index: 0;
 left: 0;
 top: 0;
 border-radius: 24px;
 }
 
 .paras{
 font-family: serif;
 font-size: larger;
 font-weight: 600;
 color: #f4f4f4;
 }
 
 .value{
     font-family: serif;
     font-size: larger; 
     color: #fff;
 }

 .sorty{
    display: none;
 }
 
 @media screen and (max-width: 786px) {
     .fils{
         width: 80%;
         border-right: none;
         position: absolute;
         height: auto;
         justify-content: center;
         /* background-color: white; */
         margin-top: 50px;
        z-index: 999;
        display: none;
         /* align-items: center; */
         -webkit-animation: scale-up-hor-left 1.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
             animation: scale-up-hor-left 1.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
           
         
     }
 
     @-webkit-keyframes scale-up-hor-left {
         0% {
           -webkit-transform: scaleX(0.4);
                   transform: scaleX(0.4);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
         100% {
           -webkit-transform: scaleX(1);
                   transform: scaleX(1);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
       }
       @keyframes scale-up-hor-left {
         0% {
           -webkit-transform: scaleX(0.4);
                   transform: scaleX(0.4);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
         100% {
           -webkit-transform: scaleX(1);
                   transform: scaleX(1);
           -webkit-transform-origin: 0% 0%;
                   transform-origin: 0% 0%;
         }
       }
       
       
     .fils.aps{
         display: initial;
     }

     .con{
        padding: 20px;
        display: grid;
        grid-template-columns:  1fr ;
        gap: 2rem;
        /* flex-direction: row; */
        /* flex-wrap: wrap; */
    
        /* justify-content: flex-start; */
       /* justify-self: flex-start; */
        /* height: 130vh; */
            /* overflow: auto; */
    }
 
     .containi{
         width: 100%;
         color: rgb(211, 211, 211);
     }
     .side-b{
         display: flex;
         padding-left: 15px;
         align-items: center;
         color: #1a1919;
         font-weight: 600;
     }
     .side-b span{
      font-size: 10px;
      
    }
     .side-b i{
        font-size: 25px;
        background-color: transparent;
        position: relative;
        margin-right: 10px;
        color: rebeccapurple;
     }
     .filters{
     margin-top: 10px;
     width: 90%;
     
   border-radius: 15px;
   overflow: auto;
    /* overflow: auto; */
    /* background: rgb(145, 145, 145); */
    background: #2a2a2a; 
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.55);
     box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
 }


 .grid{
    /* width: 20%; */
    justify-content: center;
    display: flex;
}

.grid i{
  background-color: transparent;
}
.grid img{
    width: 100px;
  }
.grid div{
    margin: 5px;
    /* width: 80px; */
    /* height: 30px; */
    border-radius: 5px;
    padding: 20px 25px#645074;
    background-color: transparent;
    font-weight: 400;
    
    cursor: pointer;
    transition: all 0.3s ease;
   
   justify-content: center;
  display: flex;
  align-items: center;
     box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
     7px 7px 20px 0px rgba(0,0,0,.1),
     4px 4px 5px 0px rgba(0,0,0,.1);
    outline: none;
}
.fil-icon{
  /* filter: drop-shadow(2px 2px 1px gray); */
  color: #7a59ff;
}

.side-b span{
    color: bisque;
    font-family: 'popins', sans-serif;
    letter-spacing: 1px;
}


.lis{
    display: flex;
    /* margin: auto; */
    /* border-radius: 15px; */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02),
    5px 2px 2px rgba(0, 0, 0, 0.028), 1px 5.1px 5.1px rgba(0, 0, 0, 0.035),
    0 5px 2.1px rgba(0, 0, 0, 0.042), 1px 3.7px 2.8px rgba(0, 0, 0, 0.05),
    0 1px 1px rgba(0, 0, 0, 0.07);
    background-color: #253648;
    border-radius: 20px;
    /* height: 100px; */
    
  }
  .card_img{
    width: auto;
    height: auto;
    object-fit:fill;
    border-radius: 15px;
    
}

  .hs{
    display: none;
}

.sorty{
    display: initial;
    font-size: larger;
}

.sort{
    justify-content: center;
    width: 150px;
    display: flex;
}

.pro_banner{
    /* height: 250px; */
    width: 100%;
    background: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    /* box-shadow: -1px 13px 24px -15px rgba(245,193,245,1); */
    border-bottom: 1px solid #4647479c;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 10px 10px 10px;
    flex-direction: column;
    gap: 1rem;
}

.box-add-shad{
    width: 100%;
}

.box{
    
    width: 100%;
}
     }
 

      @media screen and (max-width: 1650px)
     {
        .carda{
            width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
            
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
     }

     @media screen and (max-width: 1320px)
     {
        .carda{
            width: 200px;
            height: 200px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
           
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
     }

     @media screen and (max-width: 1290px)
     {
        .carda{
            width: 200px;
            height: 200px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
         
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
     }

     @media screen and (max-width: 1260px)
     {
        .carda{
            width: 200px;
            height: 200px;
            border-radius: 20px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
           
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
     }

     @media screen and (max-width: 1268px)
     {
        .carda{
            width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
           
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
     }

     

     @media screen and (max-width: 1238px)
     {
        .carda{
            width: 200px;
             height: 200px;
             border-radius: 20px;
             overflow: hidden;
             position: relative;
             cursor: pointer;
            
             /* box-shadow: 0.1px 0.2px 5px 0px rgba(139, 139, 139, 0.75); */
           
         }
         .con{
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr ;
            gap: 2rem;
            /* flex-direction: row; */
            /* flex-wrap: wrap; */
        
            /* justify-content: flex-start; */
           /* justify-self: flex-start; */
            /* height: 130vh; */
                /* overflow: auto; */
        }


         
     }

     
     @media screen and (max-width: 900px){

        .con{
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr ;
            gap: 2rem;
            /* flex-direction: row; */
            /* flex-wrap: wrap; */
        
            /* justify-content: flex-start; */
           /* justify-self: flex-start; */
            /* height: 130vh; */
                /* overflow: auto; */
        }
     }


     @media screen and (max-width: 786px){
        .con{
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr ;
            gap: 2rem;
            /* flex-direction: row; */
            /* flex-wrap: wrap; */
        
            /* justify-content: flex-start; */
           /* justify-self: flex-start; */
            /* height: 130vh; */
                /* overflow: auto; */
        }
     }

     @media screen and (max-width: 506px){
        .con{
            padding: 20px;
            display: flex;
            /* grid-template-columns:  1fr ; */
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            gap: 2rem;
            /* flex-direction: row; */
            /* flex-wrap: wrap; */
        
            /* justify-content: flex-start; */
           /* justify-self: flex-start; */
            /* height: 130vh; */
                /* overflow: auto; */
        }
     }

     @media screen and (max-width: 506px){
        .con{
            padding: 20px;
            display: flex;
            /* grid-template-columns:  1fr ; */
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 2rem;
            /* flex-direction: row; */
            /* flex-wrap: wrap; */
        
            /* justify-content: flex-start; */
           /* justify-self: flex-start; */
            /* height: 130vh; */
                /* overflow: auto; */
        }
     }