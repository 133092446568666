

.Trend_card{
    display: grid;
    gap: 1rem;
  }
  
  
  
  .alo{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: space-around;
    
    /* background-color: #ff0000; */
    flex-wrap: wrap;
  }
  
  .cate_prjects_c{
    
    display: flex;
    flex-direction: column;
   
   gap: 1rem;
    padding: 8px;
    
  }

  .cardt {
    background: #000000;
    width: 350px;
    /* min-height: var(--card-height); */
    padding: 15px 7px 15px 7px;
    position: relative;
    min-height: 489px;
   
  
   
   
    font-size: 1.5em;
    color: rgb(88 199 250 / 100%);

  background: #000000;
  border-radius: 10px;
    box-shadow: rgba(12, 12, 12, 0.788) 0px 60px 40px -7px;
    position: relative;
    border: 1px solid rgb(0, 204, 255);
  }
  

  
  
  
  @keyframes spin {
    0% {
      --rotate: 0deg;
    }
    100% {
      --rotate: 360deg;
    }
  }
  
  .cardt a {
    color: #212534;
    text-decoration: none;
    font-family: sans-serif;
    font-weight: bold;
   
  }
  
  .cate_name{
    
    gap: 0.5rem;
    color: rgb(0, 204, 255);
    /* background: #5353533a; */
    padding: 2px;
    border-radius: 6px;
    display: grid;
    justify-content: center;
    align-items: center;
    place-items: center;
  }
  
  .more_button{
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    border-radius: 6px;
    background-color: #0e72ac;
    padding: 5px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
    transition: 0.5s ease;
    width: fit-content;
    position: absolute;
    right: 10px;
    top: 10px;
    border: 2px solid transparent;
  }
  .more_button:hover{
    
    background: transparent;
    border: 2px solid orangered;
    /* transform: scale(1.05); */
  }
  
  .cate_name img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  }
  
  .card_head{
   display: grid;
   justify-content: center;
    padding: 5px;
    place-items: center;
    
  }
  
  
  
  .cate_detail{
    display: flex;
    justify-content: center;
  
    /* height: 80%; */
    
    
  }
  
  .cate_detail img{
    border-radius: 50%;
  }
  
  .cate_detail_list{
  
    /* background: rgba(20, 20, 20, 0.808); */
    /* padding: 40px; */
    border-radius: 6px;
    
    
    display: flex;
    flex-direction: column;
    align-items: center;
   /* justify-content: center; */
    gap: 0.5rem;
    color: #ffffff;
    box-shadow: rgba(10, 7, 24, 0.25) 0px 50px 50px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(0, 0, 0, 0.35) 0px -2px 6px 0px inset;
    transition: 1s ease;
  }
  
 
  
  .cate_detail_list p{
    font-size: 13px;
    font-weight: normal;
  }
  
  
  
  
  .catie_projects_img img{
    width: 40px;
    border-radius: 50%;
  }


  
  .catie_projects{
    padding: 10px;
    display: flex;
    gap: 1rem;
    align-items: center;
    background-color: #1a1919;
    border-radius: 6px;
    transition: 1s ease;
    cursor: pointer;
  }
  
  .catie_projects:hover{
  transform: scale(1.05);
  box-shadow: rgba(0, 0, 0, 0.664) 0px 60px 40px -7px;
  
  }
  
  .catie_projects_txt h1 , p{
    font-size: 12px;
  }
  
  .catie_projects_txt h1 {
    color: rgb(0, 204, 255);
  }
  
  .catie_projects_txt p{
    color: #fff;
  }

  @media screen and (min-width: 1680px){
    .cardt{
        width: 400px;
    }
  }
/* .property{
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: grid;
    place-items: center;
    background-image: url('../../images/polygon.svg');
    background-repeat: no-repeat;
    background-size: 170px 250px;
    background-position: left 50px top 0;
}
.center{
    display: flex;
    
   
    
    padding: 15px;
}
.demo{
    margin-top: 100px;
}


.center button{
    padding: 0rem 0rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 11px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    height: 30px;
    width: 99px;
 box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
 margin-left: 50px;
   transition: 1s;
   float: right;
}

.center button:hover{
    box-shadow: 0  37px 10px -20px rgba(0, 0, 0, 0.2);
    
}

.center h1{
    font-size: 38px;
    font-family: var(--font-family);
    
    
    
}

.center i{
    color: #0199b4;
  box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}
.row{
    padding-top: 4rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.single-property{
    width: 350px;
    border-radius: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 30px;
    overflow: hidden;
    backdrop-filter: blur(10px);
    background-color: rgba(26, 26, 26, 0.8);
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
}
.column{
    padding-left: 1rem;
    padding-right: 1rem;
    transition: all 0.8s ease;

}

.column:hover{
    padding-left: 1rem;
    padding-right: 1rem;
    transform: translateY(-10px);
    
}


.p-1{
    display: flex;
    padding: 20px;
    justify-content: space-between;
}
.property-thumb{
   
    overflow: hidden;
    width: 350px;
    vertical-align: middle;
    transition: .3s;
    height: 200px;
    border-radius: 1rem 1rem 0 0;
    
    transition: all 0.8s ease;
    
    
 
}
.property-thumb::before{
    content: "";
    position: absolute;
    inset: 0;
    
    padding: 1.5px; 
    background:linear-gradient(45deg,red,blue); 
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude;
            z-index: -1;
}

.property-tag{
    background: #fdae5c;
    padding: 8px 14px;
    color: #000000;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 700;
    
    font-family: "Poppins", sans-serif;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}

.property-but{
    background: #0199b4;
    padding: 8px 14px;
    color: #fff;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}

.text{
    text-align: center;
    padding: 5px;
    color: #fffefe;
    font-size: 15px;
}

.grab-1{
    display: flex;
    text-align: center;
    color: #ffffff;
    justify-content: center;
}

.grab-2{
    display: flex;
    color: #ffffff;
    justify-content: center;
}

.grab-3{
    display: flex;
    color: #ffffff;
    justify-content: center;
}

.text-down-1{
    
    text-align: center;
    padding: 5px;
  
    font-size: 16px;
    color: #ffffff;
}

.text-down-2{
    
    width: 125px;
    text-align: center;
    padding: 5px;
color: #ffffff;
    font-size: 16px;
}

.texto-1{
   
     text-align: center;
     padding: 5px;
     color: #ffffff;
     font-size: 16px;
}

.texto-2{
  
    text-align: center;
    padding: 5px;
    color: #ffffff;
    font-size: 16px;
}

.single-property:hover .property-thumb img{
    transform: scale(1.06);
}
.property-content{
    padding: 25px;
    border-bottom: 1px solid #e8e8e8;
}
.property-content h3{
    margin-bottom: 8px;
    font-size: 16px;
    color: #001d38;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}
.mark span{
    font-size: 13px;
    font-weight: 400;
    color: #919191;
    margin-left: 5px;
    font-family: "Poppins", sans-serif;
}
.amount{
    display: inline-block;
    background: #00d363;
    border-radius: 30px;
    padding: 7px 18px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    margin-top: 13px;
    font-family: "Poppins", sans-serif;
}
.property-footer{
    padding: 12px 25px;
    height: 241px;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(135deg, #665DCD 0%, #5FA4E6 70%, rgba(210, 171, 103, 0.7) 100%) 1;
    border-radius: 20px;
    border-top: none;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}


.property-footer ul{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    border-radius: 15px;
    padding: 6px 1px 6px 12px;
    
    gap: 0.6rem;
    border-radius: 20px;
    
    transition: 0.3s;
}



.property-footer ul:hover{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0.5px 8px 0 rgba(0, 207, 180, 0.849), 3px 6px 25px 0 rgba(1, 238, 255, 0.19);
    transform: translate(10px);
    
}
.property-footer ul div{
    display: grid;
    gap: 0.3rem;
}
.property-footer ul li span p h1{
    font-family: Arial,Helvetica,sans-serif;
    color: #001d38;
    font-size: 13px;
    font-weight: 400;
    margin-left: 5px;
}

.property-footer h4{
    font-family: Arial,Helvetica,sans-serif;
    color: #fff;
    padding-bottom: 2px;
    
    margin-left: 8px;
}
.property-footer p{
    font-family: Arial,Helvetica,sans-serif;
    color: #00C9A7;
    font-size: 13px;
    font-weight: 400;
    margin-left: 8px;
}
.property-footer ul li img{
    width: 46px;
    border-radius: 60px;
}
.more-property{
    padding-top: 2rem;
}
.more-property .property-btn{
    padding: 14px 31px;
    display: inline-block;
    border-radius: 2rem;
    font-size: 15px;
    border: 1px solid #fdae5c;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5px;
    color: #fdae5c;
    transition: .5s ease;
}
.more-property .property-btn:hover{
    box-shadow: 0 0 40px 40px #fdae5c inset;
    color: white;
}
@media screen and (max-width:1156px){
    .row{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width:768px){
    .property{
        padding-bottom: 2rem;
    }
    .center{
        
        text-align: center;
        padding: 1px;
        display: block;
    }

    .center button{
        
        color: #fff;
        background: #0199b4;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 15px;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 5px;
        height: 50px;
        width: 90px;
     box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
     margin-left: 0px;
       transition: 1s;
       margin-top: 10px;
       float: none;
    }
    .row{
        grid-template-columns: 1fr;
    }
    .single-property{
        width: 500px;
    }
    
    .column{
        padding-bottom: 2rem;
    }
    .property-thumb img{
        width: 500px;
    }
    .property-content{
        padding: 15px;
    }
    .more-property{
        padding-top: .5rem;
    }
    .property-thumb{
   
        
        width: auto;
        
    }
}
@media screen and (max-width:560px){
    .single-property{
        width: 450px;
    }
    .property-thumb img{
        width: 450px;
    }
    .column{
        padding-bottom: 1rem;
    }
    .property-thumb{
   
        
        width: auto;
        
    }
}
@media screen and (max-width:483px){
    .center h3{
        font-size: 35px;
    }
    .single-property{
        width: 400px;
    }
    .property-thumb img{
        width: 400px;
    }
    .more-property .property-btn{
        padding: 12px 25px;
        font-size: 14px;
    }
    .property-thumb{
   
        
        width: auto;
        
    }
}
@media screen and (max-width:430px){
    .single-property{
        width: 350px;
    }
    .property-thumb img{
        width: 350px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
    .property-thumb{
   
        
        width: auto;
        
    }
}
@media screen and (max-width:383px){
    .property h3{
        font-size: 16px;
    }
    .property-content h3{
        font-size: 14px;
    }
    .single-property{
        width: 300px;
    }
    .property-thumb img{
        width: 300px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
    .property-footer ul li span{
        font-size: 10px;
        font-weight: 400;
        margin-left: 3px;
    }
    .property-footer ul li img{
        width: 14px;
    }
}
@media screen and (max-width:321px){
    .single-property{
        width: 270px;
    }
    .property-thumb img{
        width: 270px;
    }
    .column{
        padding-left: 15px;
        padding-right: 15px;
    }
    .property-footer{
        padding: 12px 20px;
    }
}
@media screen and (max-width:291px){
    .single-property{
        width: 230px;
    }
    .property-thumb img{
        width: 230px;
    }
    .property-content{
        padding: 10px;
    }
}

@media  screen and (min-width: 1680px){
    .property{
   
       background-position: left 200px top -14px;
    }

    
}
 */
