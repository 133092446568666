.about_sec{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .about_detail{
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 1.5rem;
  
  }
  
  .about_detail small{
    color: #9c27b0;
    font-style: italic;
    font-size: 20px;
  }
  
  .about_detail h1{
    font-size: 62px;
    background: -webkit-linear-gradient(#00ffbf, #8c00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .about_detail p{
    color: #fff;
    font-size: 15px;
  }
  
  .about_detail button{
   
    
    background-color: #0199b4;
 width: fit-content;
    
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 500;
    background: linear-gradient(56deg, rgba(117,0,245,1) 47%, rgba(212,0,238,1) 87%);
    padding: 1rem 1.1rem;
    cursor: pointer;
    outline: none;
    box-shadow: inset 0 0 0 0 black;
    border-width: 2px;
    border-style: solid;
    
    color: #fff;
    border-radius: 60px;
    border-color: #ffffff;
    transition: 1s ease;
  }
  
  
  .about_detail button:hover{
    
    box-shadow: inset 300px 0 0 0 black;
    color: #fff;
    background-color: transparent;
    transform: translateY(-10px);
  }
  

  /* Mac  */

  @media screen and (min-width: 1680px){
    .about_detail{
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2.5rem;
     
     }
  }

  @media screen and (max-width: 1024px) {
    .about_sec{
     
      grid-template-columns:  1fr;
    }

    .about_detail button{
      padding: .7rem .7rem;
    }
  }