.communication-sec{
    
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    
    .communication{
      display: grid;
      grid-template-columns: 1fr 1fr;
     gap: 0;
      /* place-items: center; */
      
    }

    .protocol-img{
      display: flex;
      align-items: center;
      justify-content: center;

    }

    

    @media screen and (max-width: 1024px){

      .communication{
        display: grid;
        grid-template-columns: 1fr;
       
        /* place-items: center; */
        
      }
    }