*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: 'Poppins';
    text-decoration: none;
    
}

body{
    /* background: linear-gradient(to right top, #0a1a37, #0a1a37, #0a1a37, #0a1a37, #0a1a37, #111732, #16152d, #181328, #000000, #160814, #000000, #000000); */
    background: #141619;
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: contain;
    /* background-color: #000; */
    /* overflow: hidden; */
    background-image: url('../src/images/hero-image-right-blur.png');
    background-position: 0% -25%;
       
     
}

a{
    color: #fffdfd;
}

 


.gradient__text{
    color: #000;
    
}

.section__margin{
    padding: 4rem 6rem;
}

@media screen and (max-width: 700px) {
   .section_padding {
    padding: 4rem;
   } 

   .section__margin{
    margin: 4rem;
   }

   .gradient__bg{
    
    /* height: 110vh; */
    background-position:bottom;
}
}

@media screen and (max-width: 500px) {
    .section_padding {
     padding: 4rem 2rem;
    } 
 
    .section__margin{
     margin: 4rem 2rem;
    }
 }