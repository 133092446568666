.lrn-card-con{
    padding: 20px;
}
.lrn-card-sub-con{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 39.5px;
}
.lrn-card-con-head{
    width: 380px;
}
.lrn-card-con h1{
    padding: 20px 0;
    color: #1d1c1c;
    margin-bottom: 25px;
    background-image: url('../images/bg.png');
   
    width: 100%;
    text-align: center;
    /* box-shadow: 0.1px 0.2px 5px 0px rgba(0,0,0,0.75); */
    background-position: center;
    border-radius: 5px;
    color: #05d6b7;
    background-position: right;
    font-family: 'popins',sans-serif;
    font-weight: normal;
}
.lrn-card iframe{
 border-radius: 5px;   
}
.lrn-card{
    width: 410px;
    /* background-color: aqua; */
}
.lrn-card h3{
    font-size: 25px;  
    text-align: center;
    color: #fff;
    font-family: 'popins',sans-serif;
    font-weight: normal;
    letter-spacing: 1px;
   }