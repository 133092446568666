/* .push__navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
   
}

.push__links{
    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.push__navbar-links_logo{
    
    
    }

    .push__navbar-links_logo a{
        text-decoration: none;
        
        }
        .push__navbar-links_logo img{
            width: 60px;
            filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));
        }

.push__navbar-links_logo p{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
   
}

.push__navbar-links_logo p{
    padding: 0.5rem 1rem;
    color: #fff;
    background: #0199b4;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -10px rgba(0, 0, 0, 0.4);
}




.push__navbar-links_containers .rob{
    text-decoration: none;
    color: #ffffff;
    padding: 0.5rem 1rem;
   
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
    border-radius: 10px;
}
.push__navbar-links_containers .rob:hover{
    box-shadow: 0 0.5em 0.5em -0.4em rgb(0, 0, 0);
    transform: translateY(-0.25em);
}



.push__navbar-links_containers .twi{
    text-decoration: none;
    color: #ffffff !important;
    padding: 0.5rem 1rem;

   
    border-radius: 5px;
    padding: 4px 8px;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
   transition: 0.5s;
    cursor: pointer;
    border-radius: 5px;
    
}
.push__navbar-links_containers .twi:hover{
    box-shadow: 0 0.5em 0.5em -0.4em black;
    transform: translateY(-0.25em);
}





.twi{
    
    border-radius: 5px;
    padding: 2px 5px;
}

.gl{
    color: #0199b4;
}

.push__navbar-links_containers{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    
}

.push__navbar-join{
display: flex;
justify-content: center;
align-items: center;
}



.push__navbar-links_containers p,
.push__navbar-join p,
.push__navbar-menu_container p {
font-family: var(--font-family);
font-weight: 500;
font-size: 18px;
line-height: 25px;
text-transform: capitalize;

margin: 0 0rem;
cursor: pointer;
}
.push__navbar-join .fa-search{
    font-size: 18px;
  
}

.push__navbar-join .srch-bx{
    margin-right: 20px;
    border-right: 1px solid gray;
    padding-right: 5px;
    display: flex;
    position: relative;
}


.push__navbar-join .tweet{
    font-size: 25px;
    margin-left: 10px;
    color: #0199b4;
 cursor: pointer;
 filter: drop-shadow(1px 1px 1px gray);
}

.push__navbar-join .srch-btn{
width: 30px;

background: linear-gradient(76deg, rgba(244,53,233,1) 1%, rgba(203,6,246,1) 57%);
border: none;
padding: 5px;
border-radius: 5px;
cursor: pointer;
}
.push__navbar-join .srch-btn i{
    color: #fff;
    
}

.push__navbar-join input{
    padding: 5px;
    border-radius: 5px;
    border: none;
    margin-right: 4px;
    
    background-color: rgba(0, 0, 0, 0.2);
    position: relative;
    -webkit-animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            color: #ffffff;
            font-family: 'popins', sans-serif;
}
.push__navbar-join input:focus{
    outline: none;
}
.dropd{
    display: none;
     position: absolute; 
    min-width:  175px;
    max-width: fit-content;
    top: 30px;
    
    border-radius: 4px;
    backdrop-filter: blur(10px);
    background: rgba(225, 225, 225, 0.2);
    z-index: 999;
    left: 0;
}

.dropd.active{
    display: initial;
    width: auto;
}

.dropd span{
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropd span img{
    width: 25px;
    border-radius: 50%;
}

.dropd ul{
 
    padding: 10px 10px;
  
}

.nav-inp{
    display: none;
    width: 0%;
}
.nav-inp.active{
    display: initial;
    width: auto;
}
.dropd li{
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
}
.dropd li:hover{
  
    color: #1d1c1c;
}

@-webkit-keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }
  @keyframes slide-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-4px);
              transform: translateX(-4px);
    }
  }
  
.nav-inp{
    display: none;
    width: 0%;
}
.nav-inp.active{
    display: initial;
    width: auto;
}
.push__navbar-join .buttonss,
.push__navbar-menu_container button {
    padding: 0.5rem 0.5rem;
    color: #ffffff;
 
    background: linear-gradient(187deg, rgba(244,53,233,1) 33%, rgba(162,6,246,1) 98%);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0  17px 10px -15px rgba(0, 0, 0, 0.4);
    
}
.push__navbar-links_containers .active{
    text-decoration: none;
    color: rgb(2, 255, 200);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

}

.push__navbar-menu{
    margin-left: 1rem;

    display: none;
    position: relative;
}

.push__navbar-menu svg{
    cursor: pointer;
}

.push__navbar-menu_container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
   z-index: 999;
    text-align: end;
    background: #163747;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 30px;
    height: 400px;
    margin-top: 1rem;
    transition: 5s;
    min-width: 260px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);

    -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-hor-right 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }
  @keyframes scale-up-hor-right {
    0% {
      -webkit-transform: scaleX(0.4);
              transform: scaleX(0.4);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  }


.push__navbar-menu_container a{
    color: aqua;
    font-size: 30px;
    text-decoration: none;
}


    .push__navbar-menu_container p {
        margin: 1rem 0;
    }

    .push__navbar-menu_container-links-sign{
        display: none;
    }

    @media screen and (max-width: 1050px) {
        .push__navbar-links_containers {
            display: none;
        }
    
        .push__navbar-menu {
            display: flex;
        }
    }


    @media screen and (max-width: 700px) {
        .push__navbar {
            padding: 2rem 4rem;
        }
    }

    @media screen and (max-width: 550px) {
        .push__navbar {
            padding: 2rem;
        }
    
        .push__navbar-join {
            display: none;
        }
    
        .push__navbar-menu_containers {
            top: 20px;
        }
    
        .push__navbar-menu_container-links-sign {
            display: block;
            
        }
        
        .push__navbar-menu_container-links-sign i{
            font-size: 28px;
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid gray;
            color: #fff;
        }
    }
 */


 .active-nav {
  position: relative;
  }
  
  .active-nav::after{
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: rgb(0, 204, 255);
  position: absolute;
  }

  .active-nav::after:hover{
    
  }

 nav{

    display: flex;
    justify-content: space-between;
    align-items: center;
   
    /* background: #2a2a2a; */
    padding: 30px 15px 10px 15px;
    font-family: 'poppins';
    /* backdrop-filter: blur(20px); */
    background: transparent;
    width: 90%;
    margin-inline: auto;
    max-width: 1920px;
    border-radius: 10px;
    position: relative;
    
    /* box-shadow: rgba(155, 155, 155, 0.25) 0px 50px 100px -20px, rgba(46, 46, 46, 0.3) 0px 30px 60px -30px, rgba(39, 39, 39, 0.35) 0px -2px 6px 0px inset; */
  }
  nav img{
    width: 100px;
    cursor: pointer;
  }
  .nav__toggle-btn{
    display: none;
  }
  
  .nav_right{
    display: flex;
    align-items: baseline;
    gap: 2rem;
    /* background-color: aqua; */
  }
  
  .nav_links ul{
    display: flex;
    gap: 1.2rem;
    list-style: none;
    flex-direction: row;
    align-items: center;
  }
  .nav_links{
    display: flex;
    align-items: baseline;
    gap: 2rem;
    flex-direction: row;
  }
  .nav_links ul li{
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    font-family: "Poppins";
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
  }
  
  .nav_links ul li:hover{
    color: rgb(0, 204, 255);
  }

  
  .bisearch{
  
    font-size: 16px;
    color: #ffffff;
  }
  
  .add-pro-push{
    display: flex;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    font-family: "Poppins";
    text-transform: capitalize;
    border: 2px solid rgb(0, 204, 255);
    padding: 10px 15px 10px 15px;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .add-pro-push:hover{
    border: 2px solid orangered;
    background-color: transparent;
  
  
  }
  
  .twitter{
    color:  #00acee;
  }

  .search button{
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-size: 20px;
    cursor: pointer;
  }

  .seach_overlay.nope{
    display: none;
  }


  .seach_overlay{
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
  }

  .seach_overlay form{
 padding: 0px 300px;
 margin-top: 80px;
 display: flex;
 width: 100%;
 /* background: #00acee; */
 flex-direction: column;
  }

  .seach_overlay input{
    width: 100%;
    padding: 15px 20px;
    background: #fff;
    border: none;
    color: rgb(61, 61, 61);
    outline: none;
    font-size: 16px;
    border-radius: 60px;
  }
  
.mob_bar{
  display: none;
}

  

  .seach_overlay ul span{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 5px;
    background: rgba(99, 98, 98, 0.877);
    border-radius: 5px;
}

.seach_overlay ul span:hover{
  background: rgba(54, 54, 54, 0.877);
}

.seach_overlay ul{
  padding:10px 0px;
  
}

  .seach_overlay ul img{

  width: 20px;
  border-radius: 50%;
  }


  @media screen and (max-width: 1024px) {
  .nav__toggle-btn{
   display: inline-block;
   background: transparent;
   font-size: 2rem;
   cursor: pointer;
   position: relative;
   border: none;
   border-radius: 6px;
   display: flex;
   align-items: center;
   justify-self: center;
   padding: 5px;
  }

  .mob_bar{
    display: flex;
  }

  nav img{
    width: 70px;
    cursor: pointer;
  }

  .nav__toggle-btn svg{
   color: rgb(0, 204, 255);
  }
  .nav_right{
   position: absolute;
   top: 100%;
   right: 0;
   flex-direction: column;
   gap: 0;
   width: 100%;
   height: 100%;
  
  }

  .nav_links ul{
    display: flex;
    flex-direction: column;
    gap: 0rem;
    list-style: none;
    align-items: end;
    width: 100%;
  }
  .nav_links ul li:hover{
    color: rgb(0, 0, 0);
  }
  .nav_links ul li:nth-child(1){
    color: rgb(255, 255, 255);
  }

  .nav_links{
    flex-direction: column;
    gap: 0;
  }

  .aman{
    width: 100%;
  }
  
  .search{
    
   box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.5);
   
   background: rgb(0, 204, 255);
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   padding: 1rem 5rem 1rem 3rem;
   
  }

  .add-pro-push{
   
    box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.5);
    
    transform-origin: top;
    background: rgb(0, 204, 255);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 5rem 1rem 3rem;
    border-radius: 0px;
    border: none;
  }

  .add-pro-push:hover{
    border: none;
    background-color: rgb(0, 204, 255);
  
  color: #000;
  }
 

  .nav_right li{
   
   box-shadow: -2rem 2rem 5rem rgba(0,0,0,0.5);
   
  
   background: rgb(52, 53, 53);
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   padding: 1rem 5rem 1rem 3rem;
}






  


  .social__links li{
   transform: scale(1);
   transition: var(--transition);
   }
.social__links li:hover{
   
   transform: scale(2);
}

  .show__nav{
   position: absolute;
   z-index: 99;
   right: 0;
   transition: 1s ease;
 /* opacity: 1; */
 display: initial;
 width: max-content;
  }
  .hide__nav{
   position: absolute;
   /* display: none; */
   transition: 1s ease;
   z-index: 99;
   /* opacity: 0; */
   display: none;
   width: max-content;
  }

  .seach_overlay form{
    padding: 0px 10px;
    margin-top: 80px;
    display: flex;
    width: 100%;
    /* background: #00acee; */
    flex-direction: column;
     }

}
